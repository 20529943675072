import React from 'react';

interface ShareWidgetProps {
	url: string;
}

const ShareWidget: React.FC<ShareWidgetProps> = ({ url }) => {
	const shareOnLinkedIn = () => {
		window.open(
			`https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
			'_blank'
		);
	};

	const shareOnFacebook = () => {
		window.open(
			`https://www.facebook.com/sharer/sharer.php?u=${url}`,
			'_blank'
		);
	};

	return (
		<div className="flex w-fit gap-3">
			<span className="font-lato text-lg font-medium text-body">
				Share:
			</span>

			<button onClick={shareOnFacebook} className="w-fit">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="11"
					height="20"
					viewBox="0 0 11 20"
					fill="none"
				>
					<g clip-path="url(#clip0_296_1626)">
						<path
							d="M9.77639 11.165L10.2947 7.57282H7.05265V5.24175C7.05265 4.25898 7.50561 3.30097 8.95767 3.30097H10.4316V0.242718C10.4316 0.242718 9.09397 0 7.81497 0C5.14488 0 3.39968 1.72039 3.39968 4.83495V7.57282H0.431641V11.165H3.39968V19.849C3.99488 19.9483 4.6047 20 5.22616 20C5.84762 20 6.45744 19.9483 7.05265 19.849V11.165H9.77639Z"
							fill="#C2A01E"
						/>
					</g>
					<defs>
						<clipPath id="clip0_296_1626">
							<rect width="11" height="20" fill="white" />
						</clipPath>
					</defs>
				</svg>
			</button>
			<button onClick={shareOnLinkedIn} className="w-fit">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
				>
					<path
						d="M5.78328 4.16677C5.78306 4.6088 5.60726 5.03263 5.29454 5.34504C4.98182 5.65744 4.55781 5.83282 4.11578 5.8326C3.67376 5.83238 3.24992 5.65657 2.93752 5.34386C2.62511 5.03114 2.44973 4.60713 2.44995 4.1651C2.45017 3.72307 2.62598 3.29924 2.9387 2.98683C3.25141 2.67443 3.67542 2.49905 4.11745 2.49927C4.55948 2.49949 4.98331 2.6753 5.29572 2.98801C5.60812 3.30073 5.78351 3.72474 5.78328 4.16677ZM5.83328 7.06677H2.49995V17.5001H5.83328V7.06677ZM11.1 7.06677H7.78328V17.5001H11.0666V12.0251C11.0666 8.9751 15.0416 8.69177 15.0416 12.0251V17.5001H18.3333V10.8918C18.3333 5.7501 12.45 5.94177 11.0666 8.46677L11.1 7.06677Z"
						fill="#C2A01E"
					/>
				</svg>
			</button>
		</div>
	);
};

export default ShareWidget;
