import React from 'react';
import Layout from '../layouts/Layout';
import ShareWidget from '../components/ShareWidget/ShareWidget';

import './index.css';

const JobDescription = ({ pageContext, location }: any) => {
	return (
		<Layout
			title={`${pageContext.title} | Dream Finders Homes`}
			desc={`${pageContext.title} | Dream Finders Homes`}
			location={location}
		>
			<section className={'px-3 py-6 pt-20 !font-lato'}>
				<div
					className={
						'container mx-auto flex flex-wrap items-start justify-between gap-6'
					}
				>
					<div className={'flex flex-col gap-6'}>
						<div className="flex flex-col gap-4">
							{pageContext.category != 'n/a' && (
								<p className="m-0 font-kiona text-base font-normal text-secondary">
									{pageContext.category}
								</p>
							)}
							<h1
								className={
									'm-0 font-kiona text-4xl font-normal text-primary'
								}
							>
								{pageContext.title}
							</h1>
							<p className="font-kiona text-base font-normal text-primary">
								{pageContext.city &&
									pageContext.city !== 'n/a' && (
										<span className="flex gap-2">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="21"
												height="20"
												viewBox="0 0 21 20"
												fill="none"
											>
												<path
													d="M10.5 1.25C8.67727 1.25207 6.92979 1.97706 5.64092 3.26592C4.35206 4.55479 3.62707 6.30227 3.625 8.125C3.625 14.0078 9.875 18.4508 10.1414 18.6367C10.2465 18.7103 10.3717 18.7498 10.5 18.7498C10.6283 18.7498 10.7535 18.7103 10.8586 18.6367C11.125 18.4508 17.375 14.0078 17.375 8.125C17.3729 6.30227 16.6479 4.55479 15.3591 3.26592C14.0702 1.97706 12.3227 1.25207 10.5 1.25ZM10.5 5.625C10.9945 5.625 11.4778 5.77162 11.8889 6.04633C12.3 6.32103 12.6205 6.71148 12.8097 7.16829C12.9989 7.62511 13.0484 8.12777 12.952 8.61273C12.8555 9.09768 12.6174 9.54314 12.2678 9.89277C11.9181 10.2424 11.4727 10.4805 10.9877 10.577C10.5028 10.6734 10.0001 10.6239 9.54329 10.4347C9.08648 10.2455 8.69603 9.92505 8.42133 9.51393C8.14662 9.1028 8 8.61945 8 8.125C8 7.46196 8.26339 6.82607 8.73223 6.35723C9.20107 5.88839 9.83696 5.625 10.5 5.625Z"
													fill="#C2A01E"
												/>
											</svg>
											{pageContext.city + ', '}
											{pageContext.state &&
												pageContext.state + ', '}
											{pageContext.country?.toLowerCase() ===
												'united states' && 'US'}
										</span>
									)}
							</p>
						</div>
						<a
							href={pageContext.url}
							className={
								'secondary-button ApplyButton ApplyButtonTop w-fit max-sm:w-full'
							}
							target={'_blank'}
						>
							Apply
						</a>
					</div>
					<ShareWidget url={location.href} />
				</div>
			</section>
			<section className={'bg-white px-3 pb-20 md:pt-6'}>
				<div className={'container mx-auto'}>
					<div
						className={
							'mt-6 flex flex-row justify-between gap-12 max-lg:flex-col'
						}
					>
						<div
							className={
								'job-description-content flex flex-col max-lg:w-full'
							}
						>
							<p
								dangerouslySetInnerHTML={{
									__html: pageContext.description
								}}
							/>

							<a
								href={pageContext.url}
								className={
									'secondary-button ApplyButton ApplyButtonBottom my-6 w-fit max-sm:w-full'
								}
								target={'_blank'}
							>
								Apply
							</a>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default JobDescription;
